@value bluxomeMediaMd, bluxomeMediaXlg from '~#src/app/assets/styles/breakpoints.values.css';

.section {
  composes: grid relative overflow-hidden from global;
  place-content: center;
  background: var(--color-expertise-neon-400);
  height: 100dvh;
  width: 100dvw;
  height: 100cqb;
  width: 100cqi;
}

.header {
  composes: top-0 left-0 from global;
  width: 100%;
  padding: 23px 0;
  z-index: 2;

  @media bluxomeMediaMd {
    padding: 19px 0;
  }
}

.primary-header {
  composes: header;
  position: absolute;
}

.secondary-header {
  composes: header;
  position: sticky;
  background: var(--color-neutrals-white);
  transition: transform 0.2s ease-in-out;
  transform: translateY(-100%);
  visibility: hidden;
  z-index: -1;
}

.secondary-header.visible {
  transform: translateY(0);
  visibility: visible;
  z-index: 2;
}

.get-started {
  display: none;

  @media bluxomeMediaMd {
    display: block;
  }
}

.grid {
  --h: 276px; /* text height */
  --t-min: 56px; /* header min height */
  --t-max: calc(100cqb - 100cqi - var(--h)); /* don't overlap the video */
  --t-ideal: calc((var(--t-min) + var(--t-max)) / 2); /* centered in available space */
  top: clamp(var(--t-min), var(--t-ideal), var(--t-max));

  composes: absolute left-0 right-0 from global;
  background: var(--color-expertise-neon-400);
  pointer-events: none;
  z-index: 2;

  @media bluxomeMediaMd {
    /* Video is placed to the right of the text on desktop */
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
  }

  @container (width > 60cqb) and (width < 480px) {
    /* The video is hidden on phones if the aspect ratio is "squished" */
    top: 50%;
    transform: translateY(-50%);
  }
}

.grid-area {
  composes: flex from global;
  white-space: pre-line;
  max-width: 32rem;
  pointer-events: auto;

  @media bluxomeMediaXlg {
    margin-left: 88px;
  }
}

.content {
  composes: grid-area;
  composes: flex-col gap-1 from global;

  & > h1 {
    font-size: 48px;
  }

  @media bluxomeMediaMd {
    gap: 32px;
    overflow: visible;

    & > h1 {
      font-size: 88px;
    }
  }
}

.buttons {
  composes: grid-area;
  composes: flex-row items-center gap-2.5 mt-1 from global;

  @media bluxomeMediaMd {
    margin-top: 12px;
  }
}
