@value bluxomeMediaLg from '~#src/app/assets/styles/breakpoints.values.css';

.hero-content-desktop {
  composes: flex flex-col justify-center from global;
  height: 100%;
}

.heading {
  margin-bottom: 16px;
}

.subheading {
  margin-bottom: 16px;

  @media bluxomeMediaLg {
    margin-bottom: 40px;
  }
}

.button {
  margin-top: 24px;
  margin-bottom: 12px;

  @media bluxomeMediaLg {
    margin-top: 0;
  }
}

.rec-button {
  width: 100%;

  @media bluxomeMediaLg {
    width: 50%;
  }
}

.view-buttons-container {
  composes: flex flex-col gap-1.5 from global;
  width: 100%;

  @media bluxomeMediaLg {
    flex-direction: row;
  }
}

.area-spacing {
  composes: mt-2.5 from global;
}

.text-spacing {
  composes: mt-1.5 from global;
}
