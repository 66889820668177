@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.values.css';

.header {
  composes: font-bold from global;
  font-size: 26px;
  line-height: 36px;
  max-width: 412px;
  margin-bottom: 12px;

  @media mBreakpointSm {
    font-size: 32px;
    line-height: 48px;
  }
}

.title {
  composes: font-bold mb-1 text-black-75 from global;
  font-size: 17px;
  line-height: 24px;

  @media mBreakpointSm {
    font-size: 20px;
    line-height: 24px;
    color: #10151b;
  }
}

.category-content {
  margin-bottom: 24px;
}

.grid-content {
  margin: 0;
  padding-right: 0;
  grid-row-gap: 8px;
  grid-column-gap: 8px;
}
