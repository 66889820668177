@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.values.css';

.container {
  composes: flex flex-col justify-end items-center from global;
  padding: 12px;
  background: var(--c-white);
  mix-blend-mode: normal;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  width: 108px;
  height: 122px;

  @media mBreakpointSm {
    background: #f5f5f5;
    box-shadow: none;
  }
}

.image {
  width: 60px;
  height: 60px;
  object-fit: contain;
  mix-blend-mode: normal;

  @media mBreakpointSm {
    mix-blend-mode: darken;
  }
}

.need-title {
  composes: text-center from global;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: var(--c-black-100);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 74px;
}

.need-sub-title {
  margin-top: 4px;
  font-size: 11px;
  line-height: 14px;
  color: var(--c-black-50);
}

.large-layout-container {
  @media mBreakpointSm {
    width: 168px;
    height: 197px;
    background: var(--c-white);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);

    & .image {
      width: 120px;
      height: 120px;
      mix-blend-mode: normal;
    }

    & .need-title {
      font-size: 18px;
      line-height: 23px;
      width: 144px;
    }

    & .need-sub-title {
      font-size: 16px;
      line-height: 14px;
    }
  }
}
