@value mBreakpointSm,mBreakpointLg from '~#src/app/assets/styles/breakpoints.values.css';

.grid-wrapper-class {
  z-index: 3;
  background: var(--c-white);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: -32px;
  width: 100%;

  @media mBreakpointSm {
    width: 50%;
    flex-grow: 0;
    border-radius: 0;
    background: var(--c-black-100);
    margin-top: 0;
  }

  @media mBreakpointLg {
    width: 100%;
  }
}

.container {
  composes: flex relative overflow-hidden flex-col from global;
  background: var(--c-black-100);
  border-bottom: 1px solid var(--c-separator-light);

  @media mBreakpointSm {
    flex-direction: row;
    border-bottom: none;
    height: 496px;
  }
}

.hero-wrapper {
  composes: flex from global;
}

.content-wrapper {
  composes: flex flex-col from global;
  padding-top: 24px;
  width: 100%;

  @media mBreakpointSm {
    padding-top: 64px;
  }
}

.expert-wrapper {
  composes: relative flex from global;
  height: 258px;
  width: 100%;
  order: -1;

  @media mBreakpointSm {
    width: 50%;
    height: 100%;
    order: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 10;
  }
}

.header {
  composes: text-left font-bold from global;
  font-size: 32px;
  line-height: 38px;
  color: var(--c-black-100);
  margin-bottom: 10px;
  text-align: left;

  @media mBreakpointSm {
    color: var(--c-white);
    font-size: 40px;
    line-height: 54px;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    max-width: 462px;
  }
}

.sub-header {
  composes: text-left from global;
  font-size: 16px;
  line-height: 20px;
  color: var(--c-black-50);
  max-width: 323px;

  @media mBreakpointSm {
    font-size: 22px;
    line-height: 28px;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    color: var(--c-white);
    opacity: 0.8;
    max-width: 400px;
  }
}

.cta-wrapper {
  composes: flex items-center from global;
  flex-direction: column;
  margin-top: 16px;

  @media mBreakpointSm {
    margin: 32px 0 24px 0;
    flex-direction: row;
  }
}

.cta-btn {
  font-size: 16px;
  line-height: 24px;
  color: var(--c-white);
  border-radius: 8px;
  padding: 10px 21px;
  width: 100%;

  @media mBreakpointSm {
    width: fit-content;
    font-size: 18px;
    line-height: 24px;
    margin: 0 15px 0 0;

    padding: 16px 21px;
  }
}

.desktop-size-shop-with-expert-cta {
  composes: underline from global;
  font-size: 18px;
  line-height: 28px;
  color: var(--c-white);
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  display: none;

  &:hover {
    color: var(--c-white);
  }

  @media mBreakpointSm {
    display: block;
  }
}

.mobile-size-shop-with-expert-cta {
  composes: underline from global;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  color: var(--c-black-100);
  display: block;
  margin: 16px 0 24px 0;
  height: 24px;

  &:hover {
    color: var(--c-black-100);
  }

  @media mBreakpointSm {
    display: none;
  }
}
.info {
  font-size: 14px;
  line-height: 24px;
  color: var(--c-black-100);
  text-align: center;
  font-weight: 700;
  display: none;

  @media mBreakpointSm {
    font-size: 16px;
    line-height: 28px;
    opacity: 0.8;
    font-weight: 400;
    color: var(--c-white);
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    text-decoration: none;
    cursor: default;
    font-weigth: bold;
    text-align: left;
    display: block;
  }
}
