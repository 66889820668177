@value bluxomeMediaMd from '~#src/app/assets/styles/breakpoints.values.css';

.popular-articles {
  background: var(--color-neutrals-100);
  padding: 32px 0 32px 16px;
  overflow-x: hidden;

  @media bluxomeMediaMd {
    padding: 56px 0;
  }
}

.carousel-heading {
  composes: flex items-center justify-between from global;
  margin-bottom: 8px;
  padding-right: 16px;

  @media bluxomeMediaMd {
    margin-bottom: 24px;
    padding-right: 0;
  }
}

.carousel {
  padding-right: 16px;

  @media bluxomeMediaMd {
    padding-right: 0;
  }
}
