@value bluxomeMediaMd from '~#src/app/assets/styles/breakpoints.values.css';

.text-field {
  --size: var(--size-prop-value, 44px);
  --border-color: var(--border-color-prop-value, var(--color-neutrals-black));
  --focus-ring-color: var(--focus-ring-color-prop-value, var(--color-neutrals-black));
  --placeholder-color: var(--placeholder-color-prop-value, var(--color-neutrals-600));

  composes: grid gap-y-2 gap-x-1 from global;
  grid-template-columns: 1fr;
  grid-template-rows: var(--size);

  &.with-button {
    grid-template-columns: 1fr;
    grid-template-rows: var(--size) var(--size);

    @media bluxomeMediaMd {
      grid-template-columns: 1fr auto;
      grid-template-rows: var(--size);
    }
  }
}

.input-wrapper {
  composes: grid gap-0 overflow-hidden from global;
  grid-template-columns: 1fr var(--size);
  background: var(--color-neutrals-white);
  border: 1px solid var(--border-color);
  border-radius: calc(var(--size) / 2);

  &.focused {
    --border-color: var(--focus-ring-color);
    box-shadow: 0 0 0 1px var(--border-color);
  }
}

.input-reset {
  appearance: none;
  border: none;
  outline: none;
  padding: 0;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
}

.input {
  composes: input-reset;
  padding-inline-start: 16px;
  width: 100%;
  height: 100%;

  &::placeholder {
    color: var(--placeholder-color);
  }
}

.search-button {
  composes: appearance-none flex items-center justify-center from global;
  width: 100%;
  height: 100%;

  & > span {
    display: contents;
  }
}
