@value bluxomeMediaLg from '~#src/app/assets/styles/breakpoints.values.css';

.request-container {
  composes: p-2.5 from global;

  @media bluxomeMediaLg {
    min-height: 320px;
  }
}

.request-heading {
  composes: heading-two text-black from global;
}

.pill-container {
  composes: flex mt-2 gap-0.5 pr-1 from global;
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.pill-container::-webkit-scrollbar {
  display: none;
}

.pill {
  composes: flex py-1 px-2 gap-0.5 text-black from global;
  border-radius: 24px;
  border: 1px solid var(--color-neutrals-black);
  white-space: nowrap;
}

.rec-text {
  composes: flex items-center gap-0.5 my-1 body-small from global;
  color: #3548f4;
}

.rounded {
  border-radius: 12px;
}

.avatar-wrapper {
  position: absolute;
  bottom: 2px;
  left: 2px;
}

.avatar {
  border: 2px solid var(--color-neutrals-white);
}

.text-container {
  composes: flex flex-col items-center justify-center from global;
  width: 100%;
  height: 100%;
  background: var(--color-neutrals-white);
  border-radius: 12px;
}

.external-url-container {
  composes: text-container;
}

.external-url {
  composes: text-black from global;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.image-container {
  position: relative;
  cursor: pointer;
  aspect-ratio: 1;
  width: 25%;
  height: auto;
  border-radius: 12px;
  background: var(--color-neutrals-white);
}

.images {
  composes: flex gap-1 from global;
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.images::-webkit-scrollbar {
  display: none;
}

.image {
  border-radius: 12px;
}

.info-container {
  composes: flex gap-1 mt-1.5 from global;
  width: 80%;

  @media bluxomeMediaLg {
    margin-top: 24px;
    width: 90%;
  }
}

.info-text-container {
  composes: flex flex-col from global;
}
