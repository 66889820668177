@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.values.css';

.grid-wrapper {
  margin-top: 80px;
  flex: none;

  @media mBreakpointSm {
    margin-top: 107px;
  }
}

.title {
  width: 240px;
  height: 36px;
  margin-bottom: 4px;

  @media mBreakpointSm {
    height: 48px;
  }
}

.sub-title {
  max-width: 714px;
  height: 40px;
  margin-bottom: 24px;

  @media mBreakpointSm {
    height: 24px;
    margin-bottom: 32px;
  }
}

.card {
  height: 140px;
}

.grid-content {
  margin: 0;
  grid-row-gap: 8px;

  @media mBreakpointSm {
    grid-row-gap: 32px;
  }
}

.tab-title-wrapper {
  composes: flex from global;
  height: 24px;
  margin-bottom: 24px;
}

.tab-title {
  margin-right: 24px;
}
