@value mBreakpointSm, bluxomeMediaMd, bluxomeMediaLtMd from '~#src/app/assets/styles/breakpoints.values.css';

.tab-nav-list {
  composes: flex font-bold px-2 from global;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-neutrals-black);
  margin: 0 -16px 24px;
  overflow-x: auto;

  @media mBreakpointSm {
    font-size: 18px;
  }
}

.tab-title {
  composes: flex-none pb-1 from global;
  opacity: 0.6;
  cursor: pointer;
  border-bottom: 3px solid transparent;
  -webkit-transition: all ease-in 0.2s;
  -moz-transition: all ease-in 0.2s;
  -o-transition: all ease-in 0.2s;
  -ms-transition: all ease-in 0.2s;
  transition: all ease-in 0.2s;

  &:not(:last-child) {
    margin-right: 16px;

    @media mBreakpointSm {
      margin-right: 24px;
    }
  }

  &:hover {
    opacity: 1;
    color: var(--color-neutrals-black);
    border-bottom: 3px solid var(--color-neutrals-300);
  }
}

.tab-title-active {
  opacity: 1;
  color: var(--color-neutrals-black);
  border-bottom: 3px solid var(--color-neutrals-black);

  &:hover {
    border-bottom: 3px solid var(--color-neutrals-black);
  }
}

.tab-panel {
  display: none;
}

.tab-panel-active {
  display: block;
}

.grid-content {
  margin: 0;
  row-gap: 8px;
  column-gap: 8px;

  @media mBreakpointSm {
    row-gap: 32px;
    column-gap: 16px;
  }
}

.hide-below-md {
  @media bluxomeMediaLtMd {
    display: none;
  }
}

.hide-above-md {
  @media bluxomeMediaMd {
    display: none;
  }
}

.scout-tab-nav-list {
  composes: hide-above-md;
  composes: grid gap-0 overflow-hidden radius-full from global;
  grid-template-columns: 50% 50%;
  margin: 0 var(--grid-margin-size, 16px) 16px;
  background-color: var(--color-neutrals-white);
  border: 1px solid var(--color-neutrals-black);
  height: 48px;
}

.scout-panels {
  @media bluxomeMediaMd {
    display: flex;
    flex-direction: column;
    gap: 56px;
  }
}

.scout-tab-title {
  composes: flex items-center justify-center radius-full text-center from global;
  background: transparent;
  color: var(--color-neutrals-black);
  padding-bottom: 2px;
}

.scout-tab-title-active {
  background: var(--color-neutrals-black);
  color: var(--color-neutrals-white);
}
