@value bluxomeMediaMd, bluxomeMediaLg from '~#src/app/assets/styles/breakpoints.values.css';

.card-wrapper {
  height: 100%;
}

.category-card {
  composes: relative from global;
  cursor: pointer;
  height: 100%;
}

.standard-image-container {
  composes: radius-2 relative from global;
  background: var(--c-black-5);
  height: 140px;

  &:hover {
    background: #f2f2f2;
  }
}

.standard-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  mix-blend-mode: darken;
  max-height: 140px;
  transform-origin: 50% 100%;
  transition: all ease-in 0.1s;

  &:hover {
    transform: scale(1.04);
  }
}

.category-details {
  composes: mt-1 relative text-center from global;
}

.category-title {
  composes: font-bold relative from global;
  color: var(--c-black-100);
  font-size: 18px;
  line-height: 23px;
}

.category-expert-count {
  font-size: 16px;
  color: var(--c-black-75);
}

.small-image-container {
  display: none;
}

.small-image {
  composes: standard-image;
}

.small-layout {
  & .standard-image-container {
    display: none;
  }

  & .category-card {
    display: flex;
    min-height: 68px;
    justify-content: space-between;
    background: var(--c-black-5);
    border-radius: 8px;
    position: relative;
  }

  & .category-details {
    margin: 0 0 0 12px;
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    text-align: left;
    width: 65%;
  }

  & .category-expert-count {
    font-size: 12px;
  }

  & .category-title {
    flex-shrink: 0;
    font-size: 16px;
    line-height: 20px;
    overflow-wrap: break-word;
  }

  & .small-image-container {
    display: block;
    margin-left: auto;
    width: 88px;
    height: 68px;
  }

  &:hover {
    & .small-image {
      transform: scale(1.04);
    }
  }
}

.scout-card {
  composes: relative overflow-hidden from global;
  aspect-ratio: 3 / 2;

  @media bluxomeMediaLg {
    aspect-ratio: var(--aspect-ratio-lg, 3 / 2);
  }
}

.scout-card-heading {
  composes: absolute flex flex-col items-center gap-0.5 text-center from global;
  top: 24px;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;

  @media bluxomeMediaMd {
    top: 40px;
  }
}

.scout-card-image-container {
  composes: absolute left-0 bottom-0 pointer-events-none from global;
  width: 100%;
  max-width: 67%;
  max-height: calc(100% - 72px);
  aspect-ratio: inherit;

  @media bluxomeMediaMd {
    max-height: calc(100% - 88px);
  }
}

.scout-card-image {
  width: 100%;
  transition: transform 0.2s ease-in-out;
  transform-origin: bottom center;

  &.hovered {
    transform: scale(1.04);
  }
}

.scout-card-button {
  composes: absolute from global;
  right: 24px;
  bottom: 24px;
}
