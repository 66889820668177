@value mBreakpointMd,mBreakpointLg from '~#src/app/assets/styles/breakpoints.values.css';

.featured-image {
  width: 100%;
  min-height: 332px;
  border-radius: 16px;
  object-fit: cover;
}

.container {
  height: 100%;
}

.content-wrapper {
  composes: flex flex-col justify-between from global;
  padding-top: 16px;
  color: var(--c-white);
}

.category-badge {
  composes: flex items-center from global;
  width: fit-content;
  margin: 0 16px;
  padding: 4px 16px;
  border-radius: 22px;
  background: var(--c-black-100);
}

.category-icon {
  composes: flex items-center from global;
  margin-right: 10px;
}

.category-name {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.bottom {
  composes: p-2 from global;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 66.15%);
  border-radius: 0px 0px 16px 16px;
}

.expertName {
  composes: font-bold from global;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 8px;

  @media mBreakpointMd {
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 4px;
  }
}

.expertBackground {
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  @media mBreakpointMd {
    font-size: 16px;
    line-height: 20px;
  }
}

.location-and-review {
  composes: flex flex-col justify-between from global;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  margin-top: 12px;

  @media mBreakpointLg {
    flex-direction: row;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
  }
}

.reviewContainer,
.expert-location {
  composes: flex items-center from global;
}

.icon {
  composes: flex from global;
  width: 13px;
  height: 13px;
  margin-right: 10px;
}
