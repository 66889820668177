@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.values.css';

.container {
  margin: 24px 0 0 0;
  border-bottom: 6px solid var(--c-black-5);

  @media mBreakpointSm {
    margin: 48px 0 0 0;
    padding-bottom: 48px;
    border-bottom: 1px solid var(--c-separator-normal);
  }
}

.desktop-sub-header {
  color: var(--c-black-50);
  font-size: 18px;
  line-height: 24px;
  display: none;

  @media mBreakpointSm {
    display: block;
  }
}

.header {
  composes: font-bold text-black from global;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 4px;

  @media mBreakpointSm {
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 16px;
  }
}

.mobile-sub-header {
  composes: mb-2 from global;
  font-size: 16px;
  line-height: 20px;
  color: var(--c-black-50);
  display: block;

  @media mBreakpointSm {
    display: none;
  }
}

.desktop-curations-wrapper {
  display: none;

  @media mBreakpointSm {
    display: grid;
  }
}

.mobile-curations-wrapper {
  display: block;

  @media mBreakpointSm {
    display: none;
  }
}

.more-curation-wrapper {
  composes: flex items-center from global;
  margin-top: 24px;
  cursor: pointer;
  margin-bottom: 32px;
}

.expanded-curation-wrapper {
  margin-bottom: 24px;

  & .icon {
    transform: rotate(90deg);
  }
}

.icon {
  transition: all 0.2s;
}

.cta-text {
  composes: font-bold ml-1 from global;
  font-size: 18px;
  line-height: 24px;
  color: var(--c-black-100);
}

.number {
  composes: ml-auto from global;
  padding: 4px 16px;
  background: #f2f2f2;
  border-radius: 20px;
}

.mobile-curation {
  margin-top: 34px;
}

.otherCurations {
  padding-bottom: 32px;
}
