@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.values.css';

.container {
  margin-bottom: 32px;

  @media mBreakpointSm {
    margin-bottom: 64px;
    padding-bottom: 64px;
    border-bottom: 1px solid var(--c-separator-normal);
  }
}

.header {
  composes: font-bold from global;
  font-size: 20px;
  line-height: 28px;
  color: var(--c-black-100);
  margin-bottom: 24px;

  @media mBreakpointSm {
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 32px;
  }
}

.sections-container {
  composes: flex flex-col from global;

  @media mBreakpointSm {
    flex-direction: row;
    justify-content: space-between;
  }
}

.section {
  composes: flex relative from global;
  border-bottom: 1px solid var(--c-separator-normal);
  margin-top: 16px;

  @media mBreakpointSm {
    margin-top: 0;
    border-bottom: none;
  }
}

.section-content-wrapper {
  composes: flex-auto flex justify-between from global;
  margin: 0 0 0 16px;
}

.section-header {
  composes: font-bold from global;
  font-size: 18px;
  color: var(--c-black-100);
}

.section-content {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: var(--c-black-50);
  margin: 12px 0 18px 0;

  @media mBreakpointSm {
    max-width: 384px;
  }
}

.section-cta-link {
  composes: font-bold from global;
  font-size: 16px;
  height: 24px;
  line-height: 24px;
  color: var(--c-black-87);
  display: none;

  & .section-cta-text {
    text-decoration: underline;
  }

  @media mBreakpointSm {
    display: flex;
  }
}

.phone-screen-icon {
  composes: self-center block from global;

  @media mBreakpointSm {
    display: none;
  }
}
