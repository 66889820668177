.featured-image-aspect-ratio-container {
  composes: relative from global;
  padding-top: calc(9 / 16 * 100%);
}

.featured-image {
  composes: absolute top-0 left-0 right-0 bottom-0 from global;
  object-fit: contain;
  object-position: center center;
  height: 100%;
  width: 100%;
}

.versus-images-container {
  height: 100%;
  composes: flex items-center from global;
}
