@value mBreakpointSm, mBreakpointMd, mBreakpointXxlg from '~#src/app/assets/styles/breakpoints.values.css';

.wrapper {
  position: relative;

  & :global(.slick-prev) {
    left: 4px;

    @media mBreakpointMd {
      left: -24px;
    }

    @media mBreakpointXxlg {
      left: -36px;
    }
  }

  & :global(.slick-next) {
    right: 4px;

    @media mBreakpointMd {
      right: -24px;
    }

    @media mBreakpointXxlg {
      right: -36px;
    }
  }
}
