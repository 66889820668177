@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.values.css';

.container {
  padding: 24px 16px;
  background: #f9f9f9;
  border-radius: 16px;
  display: none;
  height: 318px;

  @media mBreakpointSm {
    display: block;
  }
}

.header {
  composes: font-bold mb-1 from global;

  font-size: 26px;
  line-height: 36px;
  color: var(--c-black-100);
}

.info {
  font-size: 18px;
  line-height: 24px;
  color: var(--c-black-100);
  margin-bottom: 24px;
}
