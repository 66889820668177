@value bluxomeMediaMd from '~#src/app/assets/styles/breakpoints.values.css';

.hero-video {
  --h: 332px; /* header + text min height */
  --b-min: -8cqi; /* any lower and the main video content cut off */
  --b-max: 0px; /* don't float above the bottom border */
  --b-ideal: calc(100cqb - 100cqi - var(--h)); /* avoid the header + text */
  bottom: clamp(var(--b-min), var(--b-ideal), var(--b-max));

  composes: right-0 left-0 flex items-end justify-center from global;
  position: absolute;
  top: auto;
  width: 100%;
  height: auto;
  aspect-ratio: 1;
  z-index: 1;

  & > video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-width: 100%;
  }

  @media bluxomeMediaMd {
    bottom: 0;
    left: auto;
    max-width: min(calc(100vw - 24rem - 40px), calc(100vh - 72px));
  }

  @container (width > 60cqb) and (width < 480px) {
    /* Hide the video on phones if the aspect ratio is "squished" */
    display: none;
  }

  @container (width < 48cqb) and (width < 480px) {
    /* Make the video bigger on phones if the aspect ratio is "stretched" */
    transform: scale(1.2);
    transform-origin: bottom center;
  }
}
