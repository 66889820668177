@value bluxomeMediaLg, bluxomeMediaLtLg from '~#src/app/assets/styles/breakpoints.values.css';

.hide-lg {
  @media bluxomeMediaLg {
    display: none;
  }
}

.hide-lt-lg {
  @media bluxomeMediaLtLg {
    display: none;
  }
}

.hero {
  background: var(--color-expertise-neon-400);
  padding-bottom: 40px;

  @media bluxomeMediaLg {
    padding-block: 40px;
  }
}

.hero-content {
  composes: flex flex-col justify-center gap-2.5 px-2 from global;

  @media bluxomeMediaLg {
    gap: 40px;
    padding-left: 0;
    padding-right: 8px;
  }
}

.mobile-image-area {
  composes: hide-lg;
  composes: relative overflow-hidden from global;
  padding-bottom: 56px;

  & > img {
    width: 100%;
  }
}

.desktop-image-area {
  composes: hide-lt-lg;
  composes: relative self-center from global;
}

.product-images {
  composes: absolute grid gap-x-2 from global;
  grid-template-columns: repeat(3, 1fr);
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: clamp(0px, 100% + 64px, 56rem);

  @media bluxomeMediaLg {
    left: 32px;
    right: 32px;
    bottom: 50%;
    transform: translateY(50%);
    gap: 40px;
    width: auto;
  }
}

.product-image {
  background: var(--color-neutrals-white);
  padding: 12px;
  aspect-ratio: 4 / 5;

  & > img {
    width: 100%;
  }
}

.product-title {
  background: var(--color-neutrals-black);
  margin: -1px;
  padding: 8px;
  text-align: center;
}

.product-column {
  composes: flex flex-col gap-1.5 from global;
  min-width: 0;
  width: 100%;

  &.col-reverse {
    flex-direction: column-reverse;
  }

  &.one {
    transform: translateY(16px);
  }

  &.two {
    transform: translateY(-42px);
  }

  &.three {
    flex-direction: column-reverse;
    transform: translateY(70px);
  }
}

.message {
  composes: radius-3 from global;
  padding: 0 12px 2px;
  line-height: 40px;
  background: var(--color-brand-cream);
  border-bottom-left-radius: 0;
  align-self: flex-start;
  white-space: nowrap;

  @media bluxomeMediaLtLg {
    display: none;
  }
}
