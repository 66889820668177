@value mBreakpointSm, bluxomeMediaMd from '~#src/app/assets/styles/breakpoints.values.css';

.container {
  margin-top: 24px;
  margin-bottom: 48px;

  @media mBreakpointSm {
    margin-top: 48px;
    margin-bottom: 64px;
  }
}

.header {
  composes: font-bold from global;
  font-size: 26px;
  line-height: 36px;
  margin-bottom: 4px;
  color: var(--c-black-100);

  @media mBreakpointSm {
    font-size: 32px;
    line-height: 48px;
  }
}

.sub-header {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--c-black-50);
  margin-bottom: 24px;

  @media mBreakpointSm {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 32px;
  }
}

.scout-container {
  margin-block: 32px;

  @media bluxomeMediaMd {
    margin-block: 56px;
  }
}
