@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.values.css';

.container {
  @media mBreakpointSm {
    padding: 24px 0 24px 16px;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 16px;
    height: 318px;
  }
}

.header {
  composes: font-bold flex items-center from global;
  font-size: 26px;
  line-height: 36px;
  color: var(--c-black-100);
  margin-bottom: 18px;
  display: none;

  @media mBreakpointSm {
    display: block;
  }
}

.icon-wrapper {
  composes: inline-block from global;
  margin-left: 9px;
}

.expert-info-wrapper {
  composes: flex from global;
  margin-bottom: 12px;
}

.avatar-wrapper {
  composes: mr-1 from global;
}

.name {
  composes: underline from global;
  font-size: 16px;
  line-height: 24px;
  color: var(--c-black-100);
}

.cta {
  composes: mt-2 from global;
  display: none;

  @media mBreakpointSm {
    display: block;
  }
}

.curation-list {
  & :global(.slick-slide) {
    padding: 8px 4px;
  }

  @media mBreakpointSm {
    & :global(.slick-slide) {
      padding: 0;
    }
  }
}
