@value bluxomeMediaMd from '~#src/app/assets/styles/breakpoints.values.css';

.hero-image {
  composes: absolute from global;
  pointer-events: none;
  top: calc(50% + 1rem);
  left: 0;
  width: 100vw;
  height: auto;
  overflow-x: clip;
  overflow-y: visible;
  transform: translate(0, 100%);
  opacity: 0;
  z-index: 1;
  animation-name: slideUpInfinite;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-duration: 8s;
  animation-iteration-count: infinite;

  @media bluxomeMediaMd {
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
    overflow: visible;
    animation-name: slideUpOnce;
    animation-duration: 0.4s;
    animation-iteration-count: 1;
  }
}

.hero-image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: var(--bg) center top/cover no-repeat;
  min-height: 100%;
  width: 100%;
  transform: translate(0, 0);
}

.golf-bag {
  aspect-ratio: 703 / 1033;
  animation-delay: 4s;
}

.golf-glove {
  aspect-ratio: 1;
  display: none;
}

.golf-club {
  left: 2rem;
  width: calc(100vw - 4rem);
  top: calc(50% + 2rem);
  aspect-ratio: 2066 / 3737;
  animation-delay: 0s;
}

@media bluxomeMediaMd {
  .golf-club {
    bottom: -1vw;
    left: 48vw;
    width: 24vw;
    max-width: 32vh;
    animation-delay: 0.4s;

    &::before {
      transform: rotate(23deg);
    }
  }

  .golf-glove {
    display: block;
    top: 1vw;
    left: 58vw;
    width: 28vw;
    max-width: 54vh;
    animation-delay: 0.8s;
    animation-name: slideDownOnce;
    transform: tramslate(0, -100%);

    &::before {
      transform: rotate(-26deg);
    }
  }

  .golf-bag {
    right: -6vw;
    bottom: -3vw;
    width: 32vw;
    max-width: 48vh;
    animation-delay: 1.2s;

    &::before {
      transform: rotate(-6deg);
    }
  }
}

.ski-helmet {
  aspect-ratio: 1;
  animation-delay: 4s;
}

.ski-boot {
  aspect-ratio: 1;
  animation-delay: 0s;
  top: 50%;
  left: -10vw;
  width: 110vw;

  &::before {
    transform: rotate(-38deg);
  }
}

.ski {
  aspect-ratio: 159 / 1024;
  display: none;
}

@media bluxomeMediaMd {
  .ski-helmet {
    bottom: 4vw;
    left: 48vw;
    width: 28vw;
    max-width: 42vh;
    animation-delay: 0.4s;

    &::before {
      transform: rotate(18deg);
    }
  }

  .ski-boot {
    top: -7vw;
    left: 58vw;
    width: 30vw;
    max-width: 54vh;
    animation-delay: 0.8s;
    animation-name: slideDownOnce;
    transform: tramslate(0, -100%);

    &::before {
      transform: rotate(-25deg);
    }
  }

  .ski {
    display: block;
    right: 6vw;
    bottom: -50%;
    width: 16vw;
    max-width: 16vh;
    animation-delay: 1.2s;

    &::before {
      transform: rotate(10deg);
    }
  }
}

@keyframes slideUpInfinite {
  0%,
  50%,
  100% {
    opacity: 0;
    transform: translate(0, 100%);
  }
  5%,
  45% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes slideUpOnce {
  from {
    opacity: 0;
    transform: translate(0, 100%);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes slideDownOnce {
  from {
    opacity: 0;
    transform: translate(0, -100%);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
