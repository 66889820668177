.container {
  composes: flex justify-center px-2 from global;
  margin-top: 24px;
}

.tick-container {
  composes: p-1 from global;
  width: 100%;
  max-width: 56px;
  height: 21px;
  cursor: pointer;
}

.tick {
  width: 100%;
  height: 100%;
  background: var(--c-black-25);
  transition: all 0.2s;
}

.current-tick {
  background: var(--c-primary);
}
