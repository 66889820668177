@value mBreakpointSm, mBreakpointMd from '~#src/app/assets/styles/breakpoints.values.css';

.gridWrapper {
  background: #111111;

  @media mBreakpointSm {
    background: white;
  }
}

.container {
  composes: flex flex-col items-center from global;
  background: #111111;
  padding: 32px 0;
  color: var(--c-white);

  @media mBreakpointSm {
    padding: 48px;

    border-radius: 32px;
  }
}

.header {
  font-weight: 700;
  font-size: 26px;
  line-height: 36px;
  margin-bottom: 16px;

  @media mBreakpointSm {
    font-size: 32px;
    line-height: 48px;
    margin: 16px 0 40px 0;
  }
}

.carousel-container {
  width: 100%;
}

.img-wrapper {
  height: 237px;
  margin-bottom: 22px;

  @media mBreakpointSm {
    height: 215px;
    margin-bottom: 16px;
  }
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  object-fit: cover;
}

.card-label {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
}

.card-content {
  font-weight: 450;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.8;
}

.carousel-container {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);

  @media mBreakpointSm {
    padding-bottom: 48px;
  }
}

.actions-wrapper {
  composes: flex justify-center from global;
  width: 100%;

  @media mBreakpointSm {
    margin-top: 32px;
    justify-content: space-between;
  }
}

.cta-btn {
  font-size: 18px;
  line-height: 24px;
}

.cta-small-screen-btn {
  composes: cta-btn;
  font-size: 16px;
  width: 100%;
  margin-bottom: 24px;
}

.apply-wrapper {
  composes: flex flex-col items-center from global;

  @media mBreakpointSm {
    flex-direction: row;
  }
}

.apply-text {
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  margin: 24px 0 8px 0;

  @media mBreakpointSm {
    margin: 0 7px 0 0;
  }
}

.apply-link {
  text-decoration-line: underline;
  color: white;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.dot-container {
  composes: flex items-start relative from global;
  margin: 24px 0 32px 0;

  @media mBreakpointMd {
    margin-top: 40px;
  }
}

.dots {
  composes: list-reset m-0 p-0 from global;
}

.dot {
  display: inline-block;
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }

  & .active {
    opacity: 1;
    background-color: var(--c-white);
  }
}

.dot-btn {
  color: white;
  padding: 0;
  border: 0;
  margin: 0;
  width: 60px;
  height: 4px;
  overflow: hidden;
  background-color: var(--c-white);
  cursor: pointer;
  opacity: 0.2;
}
