@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.values.css';

.grid-wrapper {
  composes: flex mt-3 from global;
  flex: none;

  @media mBreakpointSm {
    margin-top: 65px;
  }
}

.wrapper {
  composes: flex flex-col items-center from global;

  @media mBreakpointSm {
    align-items: flex-start;
  }
}

.header {
  margin-bottom: 16px;
  width: 343px;
  height: 66px;

  @media mBreakpointSm {
    width: 652px;
    height: 54px;
  }
}

.sub-header {
  margin-bottom: 68px;
  width: 285px;
  height: 40px;

  @media mBreakpointSm {
    width: 646px;
    height: 28px;
  }
}

.cta-wrapper {
  margin-bottom: 28px;
  width: 222px;
  height: 44px;

  @media mBreakpointSm {
    height: 56px;
    width: 517px;
  }
}

.info {
  width: 136px;
  height: 21px;

  @media mBreakpointSm {
    width: 610px;
    height: 28px;
  }
}
