@value mBreakpointSm, mBreakpointMd from '~#src/app/assets/styles/breakpoints.values.css';

.container {
  padding-top: 32px;
  margin-bottom: 32px;

  @media mBreakpointMd {
    padding-top: 0px;
    margin-bottom: 64px;
  }
}

.header {
  font-weight: 700;
  font-size: 26px;
  line-height: 36px;

  @media mBreakpointMd {
    font-size: 32px;
    line-height: 48px;
  }
}

.carousel {
  & :global(.slick-prev) {
    width: 48px;
    height: 48px;
    left: -24px;
  }

  & :global(.slick-next) {
    width: 48px;
    height: 48px;
    right: -24px;
  }
}

.button {
  width: 100%;

  @media mBreakpointMd {
    width: fit-content;
  }
}

.featured-cards-wrapper {
  composes: flex from global;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  padding: 24px 0;
}
