@value mBreakpointSm, mBreakpointMd, mBreakpointLg from '~#src/app/assets/styles/breakpoints.values.css';

.shopping-with-expert {
  @media mBreakpointSm {
    margin-bottom: 64px;
  }
}

.top-spacing {
  margin-top: 32px;

  @media mBreakpointLg {
    margin-top: 56px;
  }
}
