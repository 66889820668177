@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.values.css';

.container {
  margin-bottom: 64px;

  & :global(.slick-slide) {
    height: 80px;
  }
}

.header {
  composes: font-bold from global;
  color: var(--c-black-100);
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 24px;

  @media mBreakpointSm {
    font-size: 32px;
    line-height: 48px;
  }
}

.brand-container {
  composes: flex flex-auto justify-center from global;
  padding: 0px 20px;
  background: #f9f9f9;
  border-radius: 8px;
  margin-right: 8px;
  width: fit-content !important;

  @media mBreakpointSm {
    margin-right: 16px;
  }
}

.img {
  width: 80px;
  height: 80px;
  object-fit: contain;
  mix-blend-mode: darken;
}

.reversed-container {
  margin-top: 12px;

  @media mBreakpointSm {
    overflow: hidden;
    height: 0;
    margin-top: 0;
  }
}

.container.inverted {
  background: var(--color-neutrals-black);
  margin-bottom: 0;
  padding: 12px 0;

  & .header,
  & .reversed-container {
    display: none;
  }

  & .brand-container {
    background: var(--color-neutrals-black);
    padding: 0px 54px;
  }

  & .img {
    mix-blend-mode: lighten;
    filter: invert(1);
  }
}
