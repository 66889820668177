@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.values.css';

.wrapper {
  width: 100%;
}

.expert-info-wrapper {
  composes: absolute from global;
  width: 100%;
  bottom: 58px;
  z-index: 2;
  left: 16px;

  @media mBreakpointSm {
    bottom: 48px;
    left: 40px;
  }
}

.expert-name {
  composes: font-bold from global;
  font-size: 22px;
  line-height: 32px;
  color: var(--c-white);
  text-shadow: 0px 0px 20px #000000;

  @media mBreakpointSm {
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 6px;
  }
}

.expert-info {
  font-size: 16px;
  line-height: 24px;
  color: var(--c-white);
  text-shadow: 0px 0px 12px #000000;

  @media mBreakpointSm {
    font-size: 22px;
    line-height: 32px;
  }
}

.cover-image {
  width: 100%;
  object-fit: cover;
  height: 258px;

  @media mBreakpointSm {
    height: 496px;
  }
}

.cover-gradient {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 126px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  opacity: 0.8;

  @media mBreakpointSm {
    height: 193px;
  }
}
